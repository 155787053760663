<template>
  <div>
    购物车

    <Tabbar />

  </div>
</template>
<script>
import Tabbar from '@/component/Tabbar'

export default {
  components: {
    Tabbar
  },
  data() {
    return {}
  }
}
</script>
